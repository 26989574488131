import React from "react";
//import Logo from "./Pictures/Logo.jpeg";
import Email from "./Email";
import ReactPlayer from "react-player/youtube";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export class Home extends React.Component {
  render() {
    return (
      <div className="Container">
        <div className="ContainerHome">
          <div className="LogoHomeCont">
            <div className="LogoHomeOp" />
            {
              //<div className="HomeTitle">Everhart Precision Ag.</div>
            }
            <img
              src={"/Pictures/Logo-removebg.png"}
              className="HomeTitleLogo"
              alt=""
            />
            <div className="triangleBR"></div>

            <div className="triangleBR2"></div>
            <div className="parallax">
              <div
                style={{
                  height: "100vh",
                }}
              ></div>
            </div>
            {/*<img src={"/Pictures/DJI_0075.JPG"} className="LogoHome" alt="" />*/}
          </div>
          <div className="Cont">
            <div className="vidCont">
              {/*<div className="HomeBar"></div>*/}

              <div className="HomeCarasTest">
                <Carousel
                  showStatus={false}
                  autoPlay={true}
                  interval={5000}
                  infiniteLoop={true}
                  showThumbs={false}
                >
                  <div>
                    <img className="carPic" src={"/Pictures/C1.webp"} alt="" />
                  </div>
                  <div>
                    <img className="carPic" src={"/Pictures/C2.webp"} alt="" />
                  </div>
                  <div>
                    <img className="carPic" src={"/Pictures/C4.webp"} alt="" />
                  </div>
                  <div>
                    <img
                      className="carPic"
                      src={"/Pictures/Corn.webp"}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="carPic"
                      src={"/Pictures/Home2.webp"}
                      alt=""
                    />
                  </div>

                  <div>
                    <img
                      className="carPic"
                      src={"/Pictures/Home3.webp"}
                      alt=""
                    />
                  </div>

                  <div>
                    <img
                      className="carPic"
                      src={"/Pictures/Home4.webp"}
                      alt=""
                    />
                  </div>

                  <div>
                    <img
                      className="carPic"
                      src={"/Pictures/Home5.webp"}
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="carPic"
                      src={"/Pictures/Home6.webp"}
                      alt=""
                    />
                  </div>
                </Carousel>
              </div>
            </div>
            <div className="HomeCaras">
              <div className="HomeVideo">
                <ReactPlayer
                  style={{ aspectRatio: 16 / 9 }}
                  width={"100%"}
                  height={"100%"}
                  url="https://youtu.be/MRLg4o2NWgQ"
                  controls={true}
                />
              </div>
            </div>

            <div className="HomeBarTwo">
              <div className="triangleBL2"></div>
              <div className="triangleBL"></div>
            </div>
              <div className="HomeCont1">
                <div className="HomeContCont"> 
                  <div className="triangleTR2"></div>
                  <div className="triangleTL2"></div>
                </div>
              </div>
            <div className="HomebarVertLeft"></div>
            <div className="LogoOne">
              <img
                src={"/Pictures/Logo-removebg no name.png"}
                className="Logo"
                alt=""
              />
            </div>
            <div className="LogoTwo">
              <img
                src={"/Pictures/channel-seed.webp"}
                className="Logo"
                alt=""
              ></img>
            </div>
            <div className="TextTwo">

            <div
                className="LinkHomeCont"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div
                  className="LinkHome"
                  style={{
                    marginTop: "5%",
                    borderRadius: "5px",
                    backgroundImage:
                      "linear-gradient(180deg,#32343d,#2c2b32,#242227,#1d1a1d,#141113,#141113,#141113,#141113,#1d1a1d,#242227,#2c2b32,#32343d)",
                  }}
                >
                  <a
                    href="https://www.mybayerplus.com/bayer-plus-rewards-calculator"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="LinkOne"
                    style={{ marginLeft: "0%" }}
                  >
                    <div className="LinkImgCont">
                      <img
                        src={"/Pictures/BayerCalc.png"}
                        className="LinkImg"
                        alt=""
                      ></img>
                      <div className="LinkTextTitle">Bayer PLUS Rewards Calculator</div>
                    </div>
                    <div>
                      <div className="LinkText">
                        <div className="special">Link</div>
                      </div>
                    </div>
                  </a>
                </div>

              </div>
            </div>
            <div className="HomeBarThree">
            <div className="HomeCont1">
                <div className="HomeContCont"> 
                  <div className="triangleTR2"></div>
                  <div className="triangleTL2"></div>
                </div>
              </div>
            </div>
            
            <div className="HomeCont3">
              <div className="TextTwo">
              <div className="TwoTitleCont">
                <div className="TwoTitle">At Everhart Precision Ag</div>
                <div className="halfBorder"></div>
              </div>
              <div className="TwoCont">
                <div className="Two">
                  We strive to provide excellent service in all your seed needs
                  and guidance in precision agriculture technologies. helping
                  producers be the most successful by providing high quality
                  seed, precision ag, agronomy, and local research data and
                  sound advice.
                </div>
              </div>

              <div className="TwoListCont">
                <div className="TwoTitle">
                  <div>Our Services</div>
                </div>
                <ul className="TwoList">
                  <li>Channel, Corn, Soybeans, Grain Sorghum and Forgaes</li>
                  <li>WL Alfalfa</li>
                  <li>Seed Wheat</li>
                  <li>Cover crops and Triticale</li>
                  <li>Grid Soil Sampling</li>
                  <li>Pivot Bio</li>
                  <li>Seed Treating</li>
                  <li>Seed Delivery</li>
                </ul>
              </div>
              </div>
              <div className="PDFCont">
                  <div>
                    <img 
                    src={"/Pictures/Weed Management.webp"}
                    className="LinkImg"
                    alt="">
                    </img>
                  </div>

                  <div>
                    <img 
                    src={"/Pictures/2025 Bayer1.webp"}
                    className="LinkImg"
                    alt="">
                    </img>
                  </div>
                  <div>
                    <img 
                    src={"/Pictures/2025 Bayer2.webp"}
                    className="LinkImg"
                    alt="">
                    </img>
                  </div>
                </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  width: "100%",
                  padding: "3%"}}
              >
                
                {/*<div
                  className="HomeCont3TitleText"
                  style={{
                    flex: 1,
                    textAlign: "center",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "90%",
                    paddingRight: "5%",
                    paddingLeft: "5%",
                    marginBottom: "2%",
                  }}
                >
                  Legal Disclaimer
                </div>*/}
                {/*<div
                  className="HomeCont3Text"
                  style={{
                    flex: 1,
                    textAlign: "center",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "90%",
                    paddingRight: "5%",
                    paddingLeft: "5%",
                    marginBottom: "1%",
                  }}
                >
                  On Feb. 14, 2024, in response to the U.S. District Court
                  ruling, the EPA issued an Existing Stocks Order "to allow for
                  limited sale and distribution of dicamba over-the-top (OTT)
                  products that were already in the possession of growers or in
                  the channels of trade and outside the control of pesticide
                  companies as of February 6, 2024," according to the EPA.
                </div>
                <div
                  className="HomeCont3Text"
                  style={{
                    flex: 1,
                    textAlign: "center",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "90%",
                    paddingRight: "5%",
                    paddingLeft: "5%",
                  }}
                >
                  Bayer welcomes the EPA’s swift action so customers who have
                  already invested in XtendiMax® can continue to use and benefit
                  from the technology this season. Our top priority is that
                  growers have the products and support they need to have a
                  successful season.
                </div>*/}
              </div>
            </div>
            <div className="HomeCont4">
              <div className="">
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    aspectRatio: 16 / 9,
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    src={"/Pictures/C3.webp"}
                    alt=""
                  ></img>
                  
                </div>
                <div className="Home3TextCont" style={{ width: "100%" }}>
                    <div
                      className="HomeCont4TitleText"
                      style={{
                        color: "white",
                      }}
                    >
                      Who We Are
                    </div>
                    <b></b>
                    <div
                      className="HomeCont4Text"
                      style={{
                        color: "white",
                        paddingTop: "1%",
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        textAlign: "center",
                      }}
                    >
                      Welcome to Everhart Precision Ag , your go-to destination
                      for premium seeds. We're a passionate team of experts
                      dedicated to bringing you the highest quality seeds. With
                      a commitment to simplicity, sustainability, and success,
                      we're here to help you grow your dreams, one seed at a
                      time. Welcome to a world of flourishing possibilities with
                      Everhart Precision Ag
                    </div>
                  </div>
              </div>
            </div>
            {/*
            <a
              href="https://www.channel.com/en-us.html"
              target="_blank"
              rel="noreferrer noopener"
              className="LinkOne"
            >
              <div className="LinkImgCont">
                <img
                  src={"/Pictures/Channel.png"}
                  className="LinkImg"
                  alt=""
                ></img>
                <div className="LinkTextTitle">Channel.com</div>
              </div>
              <div>
                <div className="LinkText">
                  Identify cover crop products and best use practices. Continue
                  to grow the knowledge database of cover crop solutions for
                  area growers. Star Seed is our supplier for these products.
                  <div className="special">Link</div>
                </div>
              </div>
            </a>
            <a
              href="https://www.gostarseed.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="LinkTwo"
            >
              <div className="LinkImgCont">
                <img
                  className="LinkImg"
                  src={"/Pictures/Starseed.png"}
                  alt=""
                ></img>
                <div className="LinkTextTitle">Gostarseed.com</div>
              </div>
              <div className="LinkText">
                You don't need to be sold to. You need insights that can help
                with your crops today. You need expert advice from local
                agronomists and Seedsmen with experience in the field. You need
                advice that yields results.
                <div className="special">Link</div>
              </div>
            </a>
              */}
            {/*
            <div className="HomeBarFour"></div>
            <div className="HomeEmail">
              <div className="HomeEmailTwo">
                <Email />
              </div>
            </div>
              */}
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import leaflet from "leaflet";
import { useRef,useState,useEffect } from "react";



export default function TestPlots() {

  const [tempImg, setTempImg] = useState("")
  const [img, setImg] = useState("")
  const mapRef = useRef();
  const userMarkerRef = useRef();
  const test = "3e";
  const openModel = (prop) =>
    {
      document.getElementsByName("test")[0].style.display="inline";
    };
  const closeModel = () =>{
    setImg("")
    document.getElementsByName("test")[0].style.display="none";
  }
  const div = document.createElement("div");
  div.innerHTML = `${tempImg}`;

  const button = document.createElement("button");
  button.setAttribute("class", "btn_class");
  button.innerHTML = test;

  const doAll=(imgPath,name)=>{
    setTempImg(imgPath)
    button.innerHTML = name;
    div.appendChild(button);
  }

  button.onclick = function() {
    console.log(tempImg)
    openModel(tempImg);
  }

  useEffect(() => {
  
    mapRef.current = leaflet
      .map('map', { scrollWheelZoom: false })
      .setView([38.59786,-97.80387], 7);
    
      leaflet
      .tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      })
      .addTo(mapRef.current);
      /*var marker1 = leaflet.marker(
        [38.29314,-97.74246]).addTo(mapRef.current);
        marker1._icon.style.filter = "hue-rotate(120deg)"
        marker1.bindPopup('<a href="/Pictures/Larson report.png" target="_blank">Baldwin enlist irrigated plot</a>')
        .on('click', function() { doAll("/Pictures/TestPlots/","")});*/
      /*var marker2 = leaflet.marker(
        [38.70726,-97.59494]).addTo(mapRef.current);
        marker2.bindPopup('<a href="www" target="_blank">Short irrigated corn</a>')
        .on('click', function() { doAll("/Pictures/TestPlots/","")});*/
      /*var marker3 = leaflet.marker(
        [38.304279,-97.759209]).addTo(mapRef.current);
        marker3.bindPopup('<a href="www" target="_blank">Baldwin dryland enlist bean</a>')
        .on('click', function() { doAll("/Pictures/TestPlots/","")});*/
      var marker4 = leaflet.marker(
        [38.99786,-97.87387]).addTo(mapRef.current);
        marker4.bindPopup(div)
        .on('click', function() { doAll("/Pictures/TestPlots/Cramton DL corn_report.webp","Cramton DL corn")});
        /*var marker5 = leaflet.marker(
        [38.89157,-97.54652]).addTo(mapRef.current);
        marker5.bindPopup('<a href="www" target="_blank">Aylward DL corn</a>')
        .on('click', function() { doAll("/Pictures/TestPlots/","")});*/
       /* var marker6 = leaflet.marker(
        [38.89150,-97.54232]).addTo(mapRef.current);
        marker6.bindPopup('<a href="www" target="_blank">Aylward DL bean</a>')
        .on('click', function() { doAll("/Pictures/TestPlots/","")});*/
        var marker7 = leaflet.marker(
        [38.75354,-97.30400]).addTo(mapRef.current);
        marker7.bindPopup(div)
        .on('click', function() { doAll("/Pictures/TestPlots/Wilson DL Corn_report.webp","Wilson DL Corn")});
        /*var marker8 = leaflet.marker(
        [38.71446,-97.31702]).addTo(mapRef.current);
        marker8.bindPopup('<a href="www" target="_blank">Wilson DL soybean</a>');*/
        /*var marker9 = leaflet.marker(
        [38.66669,-97.40382]).addTo(mapRef.current);
        marker9.bindPopup('<a href="www" target="_blank">Everhart DL corn</a>');*/
        var marker8 = leaflet.marker(
          [37.13284, -95.78558]).addTo(mapRef.current);
          marker8._icon.style.filter = "hue-rotate(120deg)"
          marker8.bindPopup(div)
          .on('click', function() { doAll("/Pictures/TestPlots/K4 Dryland 23_report.webp","K4 Dryland 23")});
          /*var marker9 = leaflet.marker(
            [(37.13284, -95.78558)]).addTo(mapRef.current);
            marker9._icon.style.filter = "hue-rotate(120deg)"
            marker9.bindPopup('<a href="/Pictures/Bevâ€TMs DL corn 23.pdf" target="_blank">Bevâ€TMs DL corn 23</a>')
        .on('click', function() { doAll("/Pictures/TestPlots/","")});*/
            /*var marker10 = leaflet.marker(
              [(37.13284, -95.78558)]).addTo(mapRef.current);
              marker10._icon.style.filter = "hue-rotate(120deg)"
              marker10.bindPopup('<a href="/Pictures/Larson.pdf" target="_blank">Larson</a>')
        .on('click', function() { doAll("/Pictures/TestPlots/","")});*/
          var marker11 = leaflet.marker(
              [38.66642, -97.40349]).addTo(mapRef.current);
              marker11.bindPopup(div)
              .on('click', function() { doAll("/Pictures/TestPlots/SeedshedEverhartDL_report.webp","Seed Shed Everhart DL")});
      },[]
      

  )
  return (
    <>
    <div name="test" class="pictureModel">
      <button className="closeBtn"type="button" onClick={closeModel}>X</button>
      <div className="pictureModelCont">
        <img className="mapPopupImg" src={tempImg}/>
      </div>
    </div>
      <div className="parallaxTopDown">
        <div className="SecondaryTextTitle">Test Plots</div>
      </div>
      <div className="gridContInfoPage">
        <div className="InfoBar"></div>
        <div className="SecondaryTextBox">
          <div className="SecondaryTextLine">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 0,
                paddingRight: "0%",
                zIndex: 1,
              }}
            >
              <div style={{}}>
                <img
                  src={"/Pictures/Logo-removebg no name.png"}
                  className="backImg" /*
                  position: absolute;
                  max-height: 700;
                  max-width: 100%;
                  left: -120px;
                  top: 0;*/
                  alt=""
                />
              </div>
            </div>

            <div className="SecondaryTextCont">
              <div className="SecondaryText" style={{paddingBottom:"2%"}}>
                The use of test plots at Everhart Precision provide reliable and
                accurate data collection specific to your agricultural
                conditions. We monitor factors like growth patters and nutrition
                stats to monitor water use efficiency and maximize crop yield.
                With a commitment to continuous improvement, we embrace the
                invaluable insights gained from test plots to offer you
                cutting-edge solutions that maximize yields, minimize inputs,
                and cultivate sustainable success.
              </div>
            </div>
          </div>
          <div className="map">
          <div id="map" ref={mapRef}></div>
          </div>
        </div>
      </div>
      <div className="Policy"></div>
    </>
  );
}
